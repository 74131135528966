<template>
    <transition name="fade">
      <div ref="modalContainer" class="modal-mask" v-if="isVisible" transition="modal" >
        <div class="popup-modal" v-if="isVisible">
            <div class="window">
                <slot></slot>
            </div>
        </div>
      </div>
    </transition>
</template>

<script>
export default {
    name: 'PopupModal',

    data: () => ({
        isVisible: false,
    }),

    methods: {
        open() {
            this.isVisible = true
        },

        close() {
            this.isVisible = false;
        },
    },
}
</script>


<style scoped lang="scss">

@import "../style/style.module.scss";
@import "../style/typo.scss";

/* css class for the transition */
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.popup-modal {
font-family: "source", Avenir, Helvetica, Arial, sans-serif;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
width: 450px;
border: 1px solid $bleuM;
border-radius: 5px;
-webkit-border-radius: 5px;
-moz-border-radius: 5px;
-ms-border-radius: 5px;
-o-border-radius: 5px;
background-color: white;
margin: 10rem auto;
position: fixed;
top: 20%;
left: 30%;
z-index: 200;
@extend %shadowL;
}

.window {
    background: #fff;
    border-radius: 5px;
    box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
    padding: 1rem;
}

.text-alert{
    font-weight: normal;
    text-align: center;
    margin: 2rem 2rem 0 2rem;
}

#btn-val-alert{
    @extend %button;
    width: 50px;
    margin: 2rem auto;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, .7);
  transition: opacity .3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>
