<template>
    <div v-for="(card, index) in cards" >
        <div v-if="(card.url_admin ==='' || $store.state.adminState)">
            <CardsContent :card="card" :index="index" :key="langKey"/>
        </div>
    </div>
</template>

<script>
import CardsContent from '@/components/CardsContent.vue';

export default {
    components: {
        CardsContent,
    },
    data(){
        return{
            cards: [],
            langstore: null,
        };
    },
    mounted() {
        // requête pour envoyer en props les infos des services (selon langues)
        this.getPrefs();
    },
    computed:{
        langKey(){
            this.langstore = this.$store.state.langKey;
        },
    },
    watch: {
        // chaque fois que langStore change, la requête pour la langue des textes s'exécute.
        langstore(newLangstore, oldLangstore) {
            if (newLangstore > oldLangstore) {
            this.getPrefs()
            }
        },
    },
    methods: {
        //refait la requête pour envoyer en props les infos des services (selon langues) à la modification de celle-ci.
        getPrefs() {
            var inputHash = this.$I18n()[this.$store.state.lang].services;
            var services = {};

            var keys = Object.keys(inputHash);
               keys.sort(function(a, b) {
               return inputHash[a].rank - inputHash[b].rank
            }).forEach(function(k) {
               services[k] = inputHash[k];
            });
            
            this.cards = services;
        },
    },
};

</script>

<style lang="scss" >
@import "../style/style.module.scss";
@import "../style/typo.scss";

</style>
