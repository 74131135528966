<template>
    <div id="wrapper-password-chge" :key="langKey" v-if="isVisible">
        <div class="cont-password cont-password-chge">
            <div class="cont-title-password">
                <p class="title-password" id="titlePassword">{{texts.title}}</p>
                <fa :icon="['fas', 'times']" @click="close" v-if="isModal"/>
            </div>
            <p class="text-password" id="t-pass"><b>{{texts.textS}}</b><br><br>{{texts.textL1}}<br>{{texts.textL2}}<br>{{texts.textL3}}</p>
            <form class="form-password" @submit.prevent="submitFormChgePass">
                <div>
                    <label for="login">{{texts.login}}</label>
                    <input v-if="profile.login"  :value="profile.login" id="login" disabled>
                    <input v-else pattern="[a-zA-Z0-9]{2,128}" v-model="form.login" id="login" :class="{ inputerror : !loginIsValid }">
                </div>
                <p v-if="connectState && !loginIsValid" class="error-msg">{{texts.logmsg}}</p>
                <div>
                    <label for="mail">{{texts.mail}}</label>
                    <input v-if="profile.login" :value="profile.contactEmail" id="mail" disabled>
                    <input v-else v-model="form.mail" id="mail" placeholder="abc@def.ghi" :class="{ inputerror : !mailIsValid }">
                </div>
                <p v-if="connectState && !mailIsValid" class="error-msg">{{texts.mailmsg}}</p>
                <button v-if="profile.login" id="submit-pass-chge">{{texts.submit}}</button>
                <button v-else :disabled="!formIsValid" id="submit-pass-chge">{{texts.submit}}</button>
            </form>
        </div>
    </div>
    <confirm-dialogue ref="confirmDialogue" />

</template>

<script>
import ConfirmDialogue from '../components/ConfirmDialogue.vue'

export default {
    components: { ConfirmDialogue },
    data(){
        return{
            isVisible: false,
            isModal: true,
            profile: [],
            texts: {
                title: null,
                textS: null,
                textL1: null,
                textL2: null,
                textL3: null,
                login: null,
                mail: null,
                submit: null,
                logmsg: null,
                mailmsg: null
            },
            commonTexts: {},
            langstore: 0,
            form: {
                login: null,
                mail: null,
            },
        };
    },
    mounted(){
        // requête pour textes selon langue
        this.getTexts();

    },
    computed: {
        // au changement de la varible de l'etat de connexion (ds le store) certains boutons apparaissent
        connectState(){
            return this.$store.state.connectState;
        },

        // recharge composant à chaque changement de langue
        langKey(){
            this.langstore = this.$store.state.langKey;
        },

        // "!!" convertie en boolean pour tester si champ est rempli
        loginIsValid(){
            return !!this.form.login && (/^[a-zA-Z0-9][a-zA-Z0-9_\.@ -]*[a-zA-Z0-9]$/.test(this.form.login))
        },

        mailIsValid(){
            return !!this.form.mail && (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,64})+$/.test(this.form.mail))
        },

        formIsValid(){
            return this.loginIsValid && this.mailIsValid
        },

    },
    watch: {
        // chaque fois que langStore change, la requête pour la langue des textes s'exécute.
        langstore(newLangstore, oldLangstore) {
            if (newLangstore > oldLangstore) {
            this.getTexts()
            }
        }
    },
    methods: {
        open(){
            this.isVisible = true;
            // requête info de profile
            //requête GET profile
            fetch(this.$baseUrl("/api/profile"))
            .then(response => response.json())
            .then(data => {
                this.profile = data;
            })
            .catch((error) => console.log(error));
        },

        close(){
            this.isVisible = false;
        },
        setModal(modal = true) {
            this.isModal = modal;
        },

        submitFormChgePass(){
          var data = {uid: this.form.login, mail: this.form.mail}
          if (this.profile.login) {
            data = {uid: this.profile.login, mail: this.profile.contactEmail}
          }
          var body = JSON.stringify( data );

          fetch(this.$baseUrl("/api/password"),
          {method: 'POST', body: body})
          .then((response) => {
          if (!response.ok) {
            const error = new Error ('Request Error',{cause: response});
            throw error
          }
          return response;
          })
          .then(response => {
            this.form.login = "";
            this.form.mail = "";

            this.close();

            this.$refs.confirmDialogue.show({
              title: this.commonTexts.generic,
              message: this.texts.genericAnswer
            })
            .finally((dialog) => {
                if (!this.isModal) {
                    this.$router.push("/");
                }
            })
            .catch((dialog) => {
                if (!this.isModal) {
                    this.$router.push("/");
                }
            });
          })
          .catch((error) => {
            this.close();
            error.cause.text().then((text)=> {
                this.$refs.confirmDialogue.show({
                title: this.commonTexts.error,
                message: `${this.commonTexts.genericError} \n ${text}`
                })
            })
            .finally((dialog) => {
                if (!this.isModal) {
                    this.$router.push("/");
                }
            })
            .catch((dialog) => {
                if (!this.isModal) {
                    this.$router.push("/");
                }
            });
          })

        },

        //refait la requête pour les textes au changement de langue
        getTexts() {
            this.texts = this.$I18n()[this.$store.state.lang].changepassword;
            this.commonTexts = this.$I18n()[this.$store.state.lang].common;
        }
    },


};

</script>



<style lang="scss">

@import "../style/style.module.scss";
@import "../style/typo.scss";
@import "../style/components.scss";

</style>
