<template>
    <div v-for="(card, index) in cards" >
        <div :id="index" v-if="fav[index] && (card.url_admin ==='' || $store.state.adminState)">
            <CardsContent :card="card" :index="index" :key="langKey"/>
        </div>
    </div>
</template>

<script>
import CardsContent from '@/components/CardsContent.vue';

export default {
    components: {
        CardsContent,
    },
    data(){
        return{
            cards: [],
            fav: [],
            langstore: null,
        };
    },
    mounted() {
        // requête pour envoyer en props les infos des services (selon langues)
        this.getPrefs();
    },
    computed:{
        langKey(){
            this.langstore = this.$store.state.langKey;
        },
    },
    watch: {
        // chaque fois que langStore change, la requête pour la langue des textes s'exécute.
        langstore(newLangstore, oldLangstore) {
            if (newLangstore > oldLangstore) {
            this.getPrefs()
            }
        },
    },
    methods: {
        getPrefs() {
            this.fav = this.$store.state.prefsInfo.prefs;
            this.cards = this.$I18n()[this.$store.state.lang].services;
        }
    },
};

</script>

<style lang="scss">
@import "../style/style.module.scss";
@import "../style/typo.scss";

</style>
