<template>

    <div class="button favoris" v-if="!loading" :key="starKey">
        <img v-if="!connectState && !fav" src="icons/20/favoris-contour.svg" title="ranger dans les favoris | bookmark it" @click="chgeFav(index)" >
        <img v-if="!connectState && (fav != undefined && fav)" src="icons/20/favoris.svg" title="enlever des favoris | unbookmark it" @click="chgeFav(index)"/>
    </div>

</template>

<script>

export default {
    props:[
        'index'
    ],
    data(){
        return{
            loading: false,
            fav: false,
            starKey: 0,
            showfav: false,
        };
    },
    mounted(){
        this.fav = this.$store.state.prefsInfo.prefs[this.index];

        // permet de savoir si on est en mode fav

        this.showfav = this.$store.state.favActivated;
    },
    computed: {
        // au changement de la varible de l'etat de connexion (ds le store) certains boutons apparaissent
        connectState(){
            return this.$store.state.connectState;
        },

    },
    methods: {
        // change l'état "pref" par son inverse
        chgeFav(indexService){
          var body = JSON.stringify({ fav : indexService});

          fetch(this.$baseUrl("/api/prefs"),
          {method: 'PATCH', body: body})
          .then((response) => {
            // requête pour envoyer ds le store les infos prefs des tuiles et langue
            fetch(this.$baseUrl("/api/prefs"))
            .then(response => response.json())
            .then(data => {
              this.$store.commit('SETPREFSINFOSTORE', data)
              this.fav = data.prefs[indexService];
              this.loading =  false;
            })
            .catch((error) => console.log(error));
          //recharge le composant pour afficher modif tuile favorite
          this.starKey += 1;

          if(this.showfav){
            document.getElementById(this.index).remove();
          }
        })
        .catch((error) => console.log(error));
      }
    },
};

</script>

<style lang="scss" scoped>
@import "../style/style.module.scss";
@import "../style/typo.scss";

svg{
    margin-left: 0.3rem;
    font-size: 1.5rem;
    color: white;
    @extend %animate;
    @extend %hover;
}

</style>
