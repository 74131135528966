<template>
    <div id="loader">
        <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        <p style="opacity: 100%; color: white;">Please Wait</p>
    </div>
</template>

<style lang="scss">

@import "../style/style.module.scss";

</style>