<template>
    <div class="button favoris" @click="activatFav" :key="langKey" :title="texts.title">
		<img v-if="!connectState && !activatedFav" src="icons/20/favoris-contour.svg" title="ranger dans les favoris | bookmark it" >
        <img v-if="!connectState && activatedFav" src="icons/20/favoris.svg" title="ranger dans les favoris | bookmark it" >
	</div>
</template>

<script>

export default {
    data(){
        return{
            texts:{
                title: null
            },
            langstore: null,
        };
    },
    mounted(){
        // requête pour textes selon langue
        this.getTexts();
        //this.activatedFav = this.$store.state.favActivated;
    },
    computed: {
        // recharge composant à chaque changement de langue
        langKey(){
            this.langstore = this.$store.state.langKey;
        },
        activatedFav() {
            return this.$store.state.favActivated;
        }
    },
    watch: {
        // chaque fois que langStore change, la requête pour la langue des textes s'exécute.
        langstore(newLangstore, oldLangstore) {
            if (newLangstore > oldLangstore) {
            this.getTexts()
            }
        }
    },
    methods:{
        activatFav(){
            this.$store.commit('SETFAVACTIVATED');
        },

        //refait la requête pour les textes au changement de langue
        getTexts() {
            this.texts = this.$I18n()[this.$store.state.lang].btnfav;
        }
    },
};
</script>

<style lang="scss" scoped>
@import "../style/style.module.scss";
@import "../style/typo.scss";
@import "../style/components.scss";
@import "../style/tuile.scss";
</style>
