import { createApp } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { faStar } from '@fortawesome/free-regular-svg-icons'
import { faGitAlt } from '@fortawesome/free-brands-svg-icons'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from '../public/json/I18n.json'

library.add(fas, faGitAlt, faStar)

let app = createApp(App)

app.config.globalProperties.$baseUrl =  url => {
  let baseURL = url;
  if (location.hostname === "localhost" || location.hostname === "127.0.0.1"){
    baseURL = "http://localhost:3000"+url;
  }
  return baseURL;
}

app.config.globalProperties.$I18n = () => { return i18n }

app.use(store)
   .use(router)
   .component('fa', FontAwesomeIcon)
   .mount('#app')
