<template>
    <div class="tuile">
        <a v-if="index != 'initpass'" :href="card.url_service"> 
			<div class="t-icon">
				<img :src="'icons/' + card.icon">
			</div>
			<div class="t-contexte">
			    <span class="titre" :id="index">{{card.long_name}}</span>
				   <div class="info" v-if="card.link.URL != '' && !connectState && activated[index] && !isGuest">
						{{card.description}}<br><a :href="card.link.URL" id="a-vpn">{{card.link.text}}</a>
				   </div>
			   <span class="info" v-else>{{card.description}}</span>
			</div>
        </a>
		<a v-else @click="showModalPassChge">
			<div class="t-icon">
				<img width="40" height="40" :src="'icons/' + card.icon">
			</div>
			<div class="t-contexte">
				<div class="titre" :id="index">{{card.long_name}}</div>
				<div class="info">{{card.description}}</div>
			</div>
		</a>
        <div class="t-misc">
            <div class="toggle" v-if="!connectState && !isGuest">
               <BtnsONOFF :index="index" :card="card" :key="stateKey"/>
            </div>

            <Stars :index="index" />

            <div class="button info">
                <a :href="card.article_help" target="_blank" title="déscription détaillée du service | help">
			    <svg width="20" height="20">
				   <path
				   d="M 10 0 A 10 10 0 0 0 0 10 A 10 10 0 0 0 10 20 A 10 10 0 0 0 20 10 A 10 10 0 0 0 10 0 z M 10 2 A 8 8 0 0 1 18 10 A 8 8 0 0 1 10 18 A 8 8 0 0 1 2 10 A 8 8 0 0 1 10 2 z M 10 4 A 1 1 0 0 0 9 5 A 1 1 0 0 0 10 6 A 1 1 0 0 0 11 5 A 1 1 0 0 0 10 4 z M 9 8 L 9 16 L 11 16 L 11 8 L 9 8 z"
				   />
			    </svg>
                </a>
		</div>
        </div>
    </div>
    <ChangePassword ref="modalPassword" v-if="index == 'initpass'" />

</template>

<script>
import Stars from '@/components/Stars.vue';
import BtnsONOFF from '@/components/BtnsONOFF.vue';
import ChangePassword from '@/components/ChangePassword.vue';

export default {
    props:[
        'card',
        'index',
    ],
    data(){
        return{
            activated: false,
        };
    },
    mounted(){
        this.activated = this.$store.state.prefsInfo.activated;
    },
    components: {
        Stars,
        BtnsONOFF,
        ChangePassword,
    },
    computed: {
        stateKey(){
            return this.$store.state.stateKey;
        },

        // au changement de la varible de l'etat de connexion (ds le store) certains boutons apparaissent
        connectState(){
            return this.$store.state.connectState;
        },

        isGuest(){
            return this.$store.state.isGuest;
        },
    },
    methods: {
        // affichage modale changement password au click de la tuile initpass
        showModalPassChge() {
            this.$refs.modalPassword.open();
        },
    },
};

</script>

<style lang="scss">
@import "../style/style.module.scss";
@import "../style/typo.scss";
@import "../style/cards.scss";
@import "../style/tuile.scss";
</style>
