import { createStore } from 'vuex'

export default createStore({
  state: {
    connectState: true,
    adminState: false,
    isGuest: true,
    prefsInfo: [],
    showStateChgeStore: false,
    deleteMail: "",
    newShellStore: "",
    newStateStore: "",
    oldStateStore: false,
    newContactMailStore: "",
    favActivated: false,
    lang: "fr",
    langKey: 0,
    stateKey: 0,
    newPersonKey: 0,
  },
  mutations: {
    SETCONNECTSTATESTORE(state, new_val){
      state.connectState = new_val;
    },

    SETADMINSTATESTORE(state, new_val){
      state.adminState = new_val;
    },

    SETISGUESTSTORE(state, new_val){
      state.isGuest = new_val;
    },

    SETPREFSINFOSTORE(state, new_val){
      state.prefsInfo = new_val;
    },

    SETSHOWSTATECHGESTORE(state, new_val){
      state.showStateChgeStore = new_val;
    },

    SETNEWSTATESTORE(state, new_val){
      state.newStateStore = new_val;
    },

    SETOLDSTATESTORE(state, new_val){
      state.oldStateStore = new_val;
    },

    SETNEWCONTACTMAILSTORE(state, new_val){
      state.newContactMailStore = new_val;
    },

    SETDELETEMAILSTORE(state, new_val){
      state.deleteMail = new_val;
    },

    SETFAVACTIVATED(state){
      state.favActivated = !state.favActivated;
    },

    SETFAVACTIVATEDFALSE(state){
      state.favActivated = false;
    },

    SETLANGSTORE(state, new_val){
      state.lang = new_val;
    },

    SETLANGKEY(state){
      state.langKey += 1;
    },

    SETSTATEKEY(state){
      state.stateKey += 1;
    },

    SETNEWPERSONKEY(state){
      state.newPersonKey += 1;
    },

  },
  actions: {
  },
  modules: {
  }
})
