<template>
		<header id="nav" :key="langKey">
      <router-link to="/"><img class="logo" alt="logo Mathrice avec retour à l'accueil" src="./assets/mathrice-logo.png"></router-link>
			<div class="mathrice">
				<span class="titre">{{texts.title}}</span>
			</div>
    <div  id="cont-logos-user">
      
      <div id="lang-btns-cont">
        <img src="./assets/icons/globe_icon.svg" alt="logo langues" class="globe">
        <input type="checkbox" id="touch">
        <div id="dropdown-content">
          <img class="flag" src="./assets/icons/french_flag.svg" alt="drapeau français" style="width:30px;" @click="setLang('fr')" :class="{ surrounded : lang === 'fr' }">
          <img class="flag" src="./assets/icons/uk_flag.svg" alt="drapeau anglais"  style="width:30px;" @click="setLang('en')" :class="{ surrounded : lang === 'en' }">
        </div>
      </div>

      <BtnFav v-if="!connectState && prefsIsSet && ($route.path == '/')" />
      <router-link class="button account" v-if="!connectState && ($route.path == '/profile')" to="/">
        <img src="icons/20/account.svg" v-bind:title="displayName">
      </router-link>
      <router-link class="button account" v-if="!connectState && ($route.path != '/profile')" to="/profile">
        <img src="icons/20/account.svg" v-bind:title="displayName">
      </router-link>
      <a v-if="!connectState" :href="connect.url"><fa icon="sign-out-alt" /></a>
      <a v-if="connectState  && ($route.path != '/password')" href="/auth/plm"  title="bouton de connexion">{{texts.login}}</a>
    </div>
  
		</header>

  <div id="rectify-nav"></div>

  <ellipsis v-if="loading" />

  <router-view v-if="!loading"/>


  <footer>
    <p><span v-html="texts.footer"></span></p>
  </footer> 
</template>

<script>
import { registerRuntimeCompiler } from '@vue/runtime-core';
import BtnFav from '@/components/BtnFav.vue';
import ConfirmDialogue from '@/components/ConfirmDialogue.vue'
import Ellipsis from '@/components/Ellipsis.vue'

export default {
  components: {
    BtnFav,
    ConfirmDialogue,
    Ellipsis,
  },
  data(){
    return{
      texts: {
        title: null,
        login: null,
      },
      langstore: 0,
      connect: [],
      displayName: '',
      loading: true,
    };
  },
  created(){
    // requête pour envoyer ds le store les infos prefs des tuiles et langue
    fetch(this.$baseUrl("/api/prefs"))
    .then(response => response.json())
    .then(data => {
        this.$store.commit('SETPREFSINFOSTORE', data)
        if (data.lang && data.lang.match(/[a-z]+/))
          this.$store.commit('SETLANGSTORE', data.lang)
        else
          this.$store.commit('SETLANGSTORE', 'fr')
        this.loading =  false;
      })
      .catch((error) => console.log(error));

    },
  mounted() {
    this.location = window.location.pathname;

    // requête connexion (sauve etat de connexion dans les store)
    fetch(this.$baseUrl("/api/profile"))
       .then(response => response.json())
       .then(data => {
          this.connect = data
          if (data.names != undefined) {
            this.displayName = data.names.displayName
          }
          this.$store.commit('SETCONNECTSTATESTORE', data.anonymous)
          this.$store.commit('SETADMINSTATESTORE', data.admin)
          this.$store.commit('SETISGUESTSTORE', ((data.login === undefined) || !!data.guest))
        })
        .catch((error) => console.log(error));

    // requête pour textes selon langue

    this.texts = this.$I18n()[this.$store.state.lang].navbar;

  },
  computed:{

    // au changement de la varible de l'etat de connexion certains boutons apparaissent
    connectState(){
        return this.$store.state.connectState;
    },

    lang(){
        this.texts = this.$I18n()[this.$store.state.lang].navbar;
        return this.$store.state.lang;
    },

    // recharge composant à chaque changement de langue
    langKey(){
        this.langstore = this.$store.state.langKey;
    },

    prefsIsSet(){
      if (this.$store.state.prefsInfo.prefs  != undefined){
        for (const pref in this.$store.state.prefsInfo.prefs) {
        if ( this.$store.state.prefsInfo.prefs[pref] == true) return true;
      }
    }
    this.$store.state.favActivated = false;
     return false;
    },
  },

  watch: {
    // chaque fois que langStore change la requête pour la langue des textes s'exécute.
    langstore(newLangstore, oldLangstore) {
      if (newLangstore > oldLangstore) {
        this.getTexts()
      }
    }
  },
  methods:{
    setLang(lang){
      var body = JSON.stringify({ lang : lang});
      this.$store.commit('SETLANGSTORE', lang);
      fetch(this.$baseUrl("/api/prefs") ,
      {method: 'PATCH', body: body})
      .then((response) => {
       console.log("langue changée");
       this.$store.commit('SETLANGKEY');
       document.getElementById("touch").checked = false;
      })
      .catch((error) => console.log(error));
    },

    //refait la requête pour les textes au changement de langue
    getTexts() {
      // requête pour envoyer ds le store les infos prefs des tuiles et langue
      fetch(this.$baseUrl("/api/prefs"))
      .then(response => response.json())
      .then(data => {
          this.texts = this.$I18n()[this.$store.state.lang].navbar;
          this.$store.commit('SETPREFSINFOSTORE', data)
          this.$store.commit('SETLANGSTORE', data.lang)
          this.loading =  false;
        })
        .catch((error) => console.log(error));
    },

    getCookie(name) {
    // Split cookie string and get all individual name=value pairs in an array
    var cookieArr = document.cookie.split(";");

    // Loop through the array elements
    for(var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");

        /* Removing whitespace at the beginning of the cookie name
        and compare it with the given string */
        if(name == cookiePair[0].trim()) {
            // Decode the cookie value and return
            return decodeURIComponent(cookiePair[1]);
        }
    }

    // Return null if not found
    return null;
}
  },
};



</script>

<style lang="css" scoped>
@import "../public/css/main.css";
</style>


<style lang="scss" scoped>
@import "./style/style.module.scss";
@import "./style/typoapp.scss";
@import "./style/app.scss";
@import "./style/font.scss";
</style>
