<template>

    <div :key="stateKeyStore">
        <div id="wrapper-password-chge" :key="langKey" :class="{ hidden : !showChgeState }">
            <div class="cont-password cont-password-chge">
                <div class="cont-title-password">
                    <p class="title-password" id="titlePassword">{{texts.title}}</p>
                    <fa :icon="['fas', 'times']" @click="closeChgeState" />
                </div>
                <p v-if="oldstate" class="text-password">{{text.textON}}</p>
                <p v-else class="text-password">{{text.textOFF}}</p>
                <div v-if="oldstate" id="btn-delete" class="btn" @click="serviceONOFF(newstate)">{{texts.ON}}</div>
                <div v-else id="btn-delete" class="btn" @click="serviceONOFF(newstate)">{{texts.OFF}}</div>
            </div>
        </div>
    </div>
    <confirm-dialogue ref="confirmDialogue" />
</template>

<script>
import ConfirmDialogue from '../components/ConfirmDialogue.vue'

export default {
    components: { ConfirmDialogue },
    data(){
        return{
            oldstate: false,
            texts:{
                title: null,
                ON: null,
                OFF: null,
            },
            commonTexts: {},
            text:{
                textON: "",
                textOFF: "",
            },
            langstore: 0,
            newstate: "",
            statekey: null,
        };
    },
    mounted(){

        this.oldstate = this.$store.state.oldStateStore;

        // requête pour textes selon langue
        this.getTexts();

    },
    computed: {
        showChgeState(){
            return this.$store.state.showStateChgeStore;
        },

        // recharge composant à chaque changement de langue
        langKey(){
            this.langstore = this.$store.state.langKey;
        },

        // recharge composant à chaque changement d'état
        stateKeyStore(){
            this.statekey = this.$store.state.newStateStore;
            this.oldstate = this.$store.state.oldStateStore;
        },
    },
    watch: {
        // chaque fois que langStore change, la requête pour la langue des textes s'exécute.
        langstore(newLangstore, oldLangstore) {
            if (newLangstore > oldLangstore) {
            this.getTexts()
            }
        },
        // chaque fois que statekey change, on va rechercher la valeur du state dans le store.
        statekey(newstatekey, oldstatekey) {
            if (newstatekey != oldstatekey) {
            this.newstate = this.$store.state.newStateStore
            this.getTexts()
            }
        }

    },
    methods: {
        closeChgeState(){
            this.$store.commit('SETSHOWSTATECHGESTORE', false)
        },

        //refait la requête pour les textes au changement de langue ou d'état
        getTexts() {
            this.texts = this.$I18n()[this.$store.state.lang].chgestate;
            this.commonTexts = this.$I18n()[this.$store.state.lang].common;

            if (this.newstate != ""){
                this.text = this.$I18n()[this.$store.state.lang].services[this.newstate].properties;
            }
        },

        // passe service à OFF si ON ou ON si OFF
        serviceONOFF(indexService){
            var body = JSON.stringify({ indexServ : indexService});
            fetch(this.$baseUrl("/api/prefs"),
            {method: 'PATCH', body: body})
            .then((response) => {
            if (!response.ok) {
                const error = new Error ('Request Error',{cause: response});
                throw error
            }
                return response;
            })
            .then((response) => {

                this.$store.commit('SETSHOWSTATECHGESTORE', false)

                fetch(this.$baseUrl("/api/prefs"))
                .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error, status = ${response.status}, message = ${response.statusText}`);
                }
                    return response;
                })
                .then(response => response.json())
                .then(data => {
                    this.$store.commit('SETPREFSINFOSTORE', data)
                 this.$store.commit('SETSTATEKEY');
                })
                .catch((error) => {
                    this.$refs.confirmDialogue.show({
                    title: this.commonTexts.error,
                    message: `${this.commonTexts.genericError} \n ${error}`
                    });
                });
            })
            .catch((error) => {
                error.cause.text().then((text)=> {
                    this.$refs.confirmDialogue.show({
                        title: this.commonTexts.error,
                        message: `${this.commonTexts.genericError} \n ${text}`
                    });
                })
            });
        },
    },
};

</script>



<style lang="scss">

@import "../style/style.module.scss";
@import "../style/typo.scss";


</style>
